
import { defineComponent } from 'vue';
import _ from 'lodash';

export default defineComponent({
    components: {},
    data() {
        return {};
    },
    computed: {},
    created: function () {
        console.log(this.$route.query.code);
        if (this.$route.query.code) {
            this.loginToIG(this.$route.query.code);
        }
    },
    methods: {
        loginToIG: function (code) {
            this.$store
                .dispatch('auth/loginToIG', code)
                .then(() => {
                    this.$router.push('/feed/media');
                })
                .catch((erorr) => {
                    console.log(erorr);
                });
        },
    },
});
